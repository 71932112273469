<template>
    <v-app>
        <app-drawer></app-drawer>
        <!--<app-header :class="welcomeStatus!=='enter'?'d-none':''"/>-->
        <v-content v-if="welcomeStatus =='enter' || welcomeRemind =='true'">
            <!--:class="welcomeStatus!=='enter'?'d-none':''"-->
            <router-view/>
        </v-content>
        <app-footer v-if="welcomeStatus =='enter' || welcomeRemind =='true'"/>
        <!--:class="welcomeStatus!=='enter'?'d-none':''"-->
        <welcome v-if="welcomeStatus !='enter' && welcomeRemind !='true'"></welcome>
    </v-app>
</template>

<script>
    import AppHeader from "components/core/header/index";
    import AppFooter from "components/core/footer/index";
    import welcome from 'components/core/welcome'
    import {mapMutations, mapState} from 'vuex'
    import AppDrawer from "components/core/header/drawer";

    export default {
        components: {AppDrawer, AppFooter, AppHeader, welcome},
        name: 'app-layout',
        data() {
            return {
                isMobile: false,
                hasHeader: false,
                hasFooter: false,
                status: 'enter',
                remind: true
            }
        },
        watch: {
            $route(to) {
                this.hasHeader = !to.meta.header_hide;
                this.hasFooter = !to.meta.footer_hide;
            }
        },
        computed: {
            ...mapState({
                welcomeStatus: state => state.base.welcomeStatus,
                welcomeRemind: state => state.base.welcomeRemind
            }),
            lang() {
                return this.$store.state.base.lang
            }
        },
        created(){
            // console.log(this.welcomeRemind)
            if(this.welcomeStatus == 'enter' || this.welcomeRemind == 'true') return
            this.status = this.welcomeStatus
            this.remind = this.welcomeRemind
            this.setWelcomeRemind(true);
            this.setWelcomeStatus('enter');
            setTimeout(()=>{
                this.setWelcomeRemind(this.status);
                this.setWelcomeStatus(this.remind);
            }, 10000)
        },
        methods: {
            ...mapMutations('base', ['setWelcomeStatus', 'setWelcomeRemind']),
        }
    }
</script>

<style lang="scss" scoped>
    /* .v-content {
         padding: 0 !important;
     }*/
</style>
