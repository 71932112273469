<template>
  <v-btn
    :color="color"
    v-bind="$attrs"
    v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
  export default {
    name: 'BaseBtn',
    inheritAttrs: false,
    props: {
      color:{
        type:String,
        default:'secondary'
      }
    },
  }
</script>
