/**
 * Created by Elvira on 2020/5/12.
 */
export default {
    finewine:{
        title:'精品葡萄酒服务',
        desc:'ASC精品酒业扎根中国葡萄酒进口行业已20余年，资金实力雄厚，秉承服务至上的宗旨，是精品葡萄酒行家。<br><br>ASC精品酒业在中国进口及经销来自波尔多的列级名庄酒，以及来自世界各地的精品葡萄酒。ASC与世界诸多名庄长期紧密合作，拥有众多渠道与资源，因此能够获取大量具有投资价值的珍稀精品葡萄酒。另外，ASC只从酒庄或从庄主授权的批发商那里直接采购高品质的葡萄酒，这无疑确保了这些美酒佳酿的纯正血统及投资价值。ASC专业的物流服务与存储条件，也帮助了这些精品葡萄酒的理想状态得以延续。<br><br>ASC聘请的葡萄酒专家为您提供专业的精品葡萄酒咨询服务，让您无论在投资还是在品鉴美酒时都能获得满足。ASC精品酒业是中国推出期酒交易的践行者，我们力图确保您在众多美酒一经上市即可拥有。'
    },
    list: [{
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/primeur/img_one.jpg'),
        title: '何为期酒',
        enTitle: 'WHAT IS EN PRIMEUR?',
        desc: '“期酒”也被称为葡萄酒期货，它与棉花、咖啡以及其他消费品在国际商品期货市场上的交易方法类似。'
    }, {
        img: '',
        title: '传统运作方式',
        enTitle: 'HOW TRADITIONALLY DOES “EN PRIMEUR” FUNCTION?',
        desc: '“期酒”出现在上世纪，作为酒庄增加现金流的一种方式。酒庄采收葡萄、将葡萄汁发酵装桶，' +
        '这之后，大约在葡萄酒上市前6个月，他们将半成品葡萄酒提早卖给葡萄酒商，这样酒庄就能从当年的' +
        '采收中获得收入，并将资金投入第二年葡萄产量的运作中。对酒商来说，这种预售使他们可以以优惠的' +
        '价格获得畅销的酒款，并在几年内不会面临到库存短缺的问题。'
    }, {
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/primeur/img_two.jpg'),
        title: '期酒品鉴',
        enTitle: '“EN PRIMEUR” CAMPAIGN',
        desc: '每年春季都是葡萄酒期货市场开盘的时候。3至4月的3周内，数千名来自世界各地的评论家、' +
        '媒体和葡萄酒商涌入波尔多参加一年一度的桶酒试饮。在该品酒会上，酒庄会提供仍在桶中酿制的葡萄酒' +
        '让专业人士品尝，从中可以获得有关当年葡萄酒品质的最初概念。虽然葡萄酒要再过18-24个月后才会装瓶' +
        '并且在今后的几年内有可能都不适合饮用，但整个葡萄酒行业都期待着每年的试饮会。更重要的是，试饮会' +
        '结束后，专家所撰写的报道或评论将最终成为消费者的购买导向并带动几周后——期货最终上市时的市场需求。'
    }, {
        img: '',
        title: '现代运作方式',
        enTitle: 'HOW DOES “EN PRIMEUR” WORK TODAY?',
        desc: '酒庄通过他们的中间商（葡萄酒经纪人）来发布期货。中间商随后通过份额将其出售给葡萄酒商。' +
        '越早的一批份额通常代表着越佳的购买时机，因为此时的价格处于相对原始的状态。不过，通常发货量非常' +
        '有限（酒庄总产量的10%），在高需求的情况下很快就会一抢而空。当一批份额售完后，接下来的价格会' +
        '上涨，紧接着会有新的一批份额入市。份额系统使酒庄能够优化其产品的价值。'
    }, {
        img: '',
        title: '如何购买期酒',
        enTitle: 'HOW TO BUY “EN PRIMEUR”',
        desc: '对那些提早就发出”预订”申请的客户，我们会在价格发布时根据我们的库存情况通知其价格和数量。' +
        '只有在客户确认接受价格之后我们才会向其开具发票。由于酒价参照直接酒庄进价标准，因此我们要求客户' +
        '立刻付款从而确保其预订量的库存，我们保留取消任何未付款/迟付款订单的权利。'
    }/*, {
        img: '',
        title: '',
        enTitle: '',
        desc: '如需了解更多期酒信息，欢迎联系我们的精品酒部门：finewineinquiry@asc-wines.com'
    }*/]
}
