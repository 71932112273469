/**
 * Created by Elvira on 2020/5/12.
 */
export default {
    finewine:{
        title:'Fine wine service',
        desc:"ASC is a professional and well-capitalized fine wine specialist and has been dealing with fine wine in China for over 20 years.<br><br>ASC leads the import and distribution of Bordeaux Grand Cru Classé and Artisan fine wines from all over the world in China. Through our relationships with the top wineries throughout the world, ASC has valuable access to an extensive range of fine wines. We ONLY source fine wines directly from the producer or merchants appointed by the Chateaux owners to ensure the provenance, authenticity and value. All our fine wines are shipped and stored in ideal conditions to preserve their freshness and lifespan.<br><br>ASC’s in-house wine experts are always on standby to advise you on your fine wine purchases, whether for collection or for pleasure.<br><br>Moreover, ASC Fine Wines is a pioneer in offering En Primeur in China, guaranteeing your access to Grand Cru Classé wines as soon as they are released."
    },
    list: [{
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/primeur/img_one.jpg'),
        title: 'What is En Primeur?',
        enTitle: 'WHAT IS EN PRIMEUR?',
        desc: '“En Primeur”, also known as wine futures, works in a similar to the way in which cotton, coffee and other consumables are traded on international commodity futures markets.' +
        '<br><br>Traders purchase the wine at a price which they expect to be less than the value when it eventually hits the market. They secure the product at a particular price, expecting to sell it at a future date and make a profit.'
    }, {
        img: '',
        title: 'How traditionally does “En Primeur” function?',
        enTitle: 'HOW TRADITIONALLY DOES FUNCTION?',
        desc: '“En Primeur” sales emerged last century as a way for the Bordeaux Chateaux owners to improve their cash flow.' +
        '<br><br>Chateaux owners would harvest their grapes, ferment and barrel the grape juice, and then, about six months later, well before the wine was ready for delivery, they would sell the unfinished wine to merchants.' +
        '<br><br>This allows the Chateaux to generate income from the current year’s harvest and apply the money to the needs for preparing the following year’s grape crop ' +
        'For merchants, these pre-sales allow them to acquire in-demand wines at favorable prices, without having to deal with the storage issue for several years.'
    }, {
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/primeur/img_two.jpg'),
        title: '“En Primeur” Campaign',
        enTitle: '“EN PRIMEUR” CAMPAIGN',
        desc: 'When the futures market opens, for three weeks during March and April each year, critics, journalists, and wine merchants from around the World descend on Bordeaux to attend the annual barrel tasting.' +
        '<br><br>Chateaux owners pull unfinished wine from barrels to provide the first indication of what the years harvest has produced.' +
        '<br><br>Though the wines will not be bottled for a further 18-24 months, and while they won’t be drinkable for several more years, the wine world awaits this annual event to take place, more importantly, the reports and reviews that emerge immediately after the campaign from the “experts” will ultimately shape consumer interest and drive demand when the futures (tranches) eventually go on sale several weeks later.'
    }, {
        img: '',
        title: 'How does “En Primeur” work today?',
        enTitle: 'HOW DOES WORK TODAY?',
        desc: 'Futures are released by the Chateaux owners through their negociants (wine brokers). The wines with the negociants are then sold to wine merchants through a tranches system ' +
        'Early tranches often represent the best buying opportunity due to the initial starting price. However, stock is often very limited (10% of the Chateaux production) and sell out very quickly if the demand is high.' +
        '<br><br>When the tranches sell out, the price is raised and another tranche goes up for sale. The tranches system enables the Chateaux to maximize the value of their wines. Futures prices are most likely the lowest price there will be for some of the most sought after wines, particularly with respect to the special vintages.'
    }, {
        img: '',
        title: 'How to buy “En Primeur”?',
        enTitle: 'HOW TO BUY?',
        desc: 'For customers who submit their early “reservations”, we will notify them when the prices are released with an offer of price and allocation, according to our availability Wines will be allocated upon acceptance of the price offered.' +
        '<br><br>Wines are offered on an ex-Bordeaux basis and immediate payment is required to secure the allocation. ASC reserves the right to cancel any unpaid orders or any late payments.'
    }/*, {
        img: '',
        title: '',
        enTitle: '',
        desc: 'For more information about the En Primeur purchase, please contact our Fine Wine Division for further information: finewineinquiry@asc-wines.com'
    }*/]
}
