import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import {i18n} from "@/plugins/vuetify";

Vue.use(VueRouter);


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0, y: 0
        }
    }
});


router.beforeEach(((to, form, next) => {
    if (to.meta.title)
        document.title = to.meta.title + ' - ' + i18n.t('common.app_title');
    next();
}));
export default router
