<template>
    <div ref="pageHeader" class="page-header d-flex flex-row align-center"
         @mouseenter="headerEnter" @mouseleave="headerLeave"
         :style="'background-color: ' + colorStyle + '; height:' + ($vuetify.breakpoint.mdAndUp ? 60 : 50)">
        <!--<v-app-bar app :height="$vuetify.breakpoint.mdAndUp?60:50" :color="color">-->
        <v-app-bar-nav-icon class="hidden-md-and-up app-nav-icon" @click="toggleDrawer"></v-app-bar-nav-icon>
        <v-container>
            <v-layout class="d-flex justify-center align-center">
                <router-link to="/" class="ml-10">
                    <v-img :src="logo" :width="$vuetify.breakpoint.mdAndUp?50:40"></v-img>
                </router-link>
                <div class="header-nav hidden-sm-and-down">
                    <ul>
                        <li class="nav-item" v-for="(nav,index) in navList" :key="index"
                            :class="navIndex==index?'nav-item-border':''">
                            <router-link  :to="nav.to?nav.to:''"><span :style="'color:'+textColor">{{$t(nav.key)}}</span></router-link>
                            <ul ref="subNavList" class="sub-nav-list" v-if="nav.items"
                                :style="'background-color: '+colorStyle">
                                <li class="sub-nav-item" v-for="(item,i) in nav.items" :key="i">
                                    <a :href="item.href?item.href:'javascript:;'" target="_blank"
                                       v-if="item.href"><span :style="'color:'+textColor">{{$i18n.t(item.key)}}</span></a>
                                    <router-link  :to="item.to?item.to:''" v-else>
                                        <span :style="'color:'+textColor">{{$i18n.t(item.key)}}</span>
                                    </router-link>
                                    <v-img width="100" class="code-img mt-md-3 ml-md-2" v-if="item.qrcode" contain
                                           :src="item.qrcode"></v-img>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <div class="language d-flex flex-row justify-between" @click="changeLanguage">
                                <div class="box bl" :class="language!='中'?'select-box':''">中</div>
                                <div class="box br" :class="language!='En'?'select-box':''">En</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </v-layout>
        </v-container>
        <v-btn class="hidden-md-and-up float-right" icon @click="changeLanguage">
            <div class="language">
                {{language}}
            </div>
        </v-btn>
    </div>
</template>

<script>
    import data from '@/config/nav'
    import {mapMutations} from 'vuex'

    export default {
        name: "app-header",
        data() {
            return {
                logo: require('assets/logo.png'),
                ...data,
                language: '',
//                navIndex: 0,
                toUrl: '/',
                colorStyle: this.color
            }
        },
        props: {
            navIndex: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: 'rgba(255,255,255,1)'
            },
            textColor: {
                type: String,
                default: 'rgba(0,0,0,1)'
            },
            changeColor: {
                type: String,
                default: 'rgba(255,255,255,1)'
            }
        },
        created() {
            this.language = this.$i18n.locale == 'en' ? '中' : 'En'
        },
        watch: {
            color(){
                this.colorStyle = this.color
            }
        },
        methods: {
            ... mapMutations('base', ['toggleDrawer']),
            headerEnter(){
                if (this.changeColor == 'rgba(255,255,255,0)') {
                    this.colorStyle = 'rgba(255,255,255,0.5)'
                } else {
                    this.colorStyle = this.changeColor
                }

            },
            headerLeave(){
                if (this.color == 'rgba(255,255,255,0.5)') {
                    this.colorStyle = 'rgba(255,255,255,0)'
                } else {
                    this.colorStyle = this.color
                }
            },
            changeLanguage() {
                var key = ''
                if (this.language == '中') {
                    key = 'zhHans'
                    this.language = 'En'
                } else if (this.language == 'En') {
                    key = 'en'
                    this.language = '中'
                }
                this.$store.dispatch('base/switchLanguage', key);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .page-header {
        width: 100vw;
        position: fixed;
        top: 0;
        z-index: 9999;
        transition: all 0.5s 0.3s ease;
    }

    .header-nav {
        margin-top: 12px;
        flex: 1;

        > ul {
            margin-left: 118px;
            display: flex;
            justify-content: space-between;
        }

    }

    .language {
        border: 2px solid black;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        font-size: 16px;
        text-align: center;
        line-height: 26px;
        cursor: pointer;
    }

    @media (max-width: $screen-md) {
        .language {
            font-size: 12px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border: 1px solid black;
        }
    }

    .nav-item {
        .language {
            width: 57px;
            height: 20px;
            border: 1px solid #333333;
            border-radius: 12px;
            position: relative;
            .box {
                /*flex: 1;*/
                position: absolute;
                top: -1px;
                width: 54%;
                height: 20px;
                text-align: center;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                background-color: transparent;
                border-radius: 12px;
            }
            .bl {
                left: 0;
            }
            .br {
                right: 0;
            }
            .select-box {
                color: white;
                background-color: #333333;
            }
        }
    }

    .nav-item {
        position: relative;
        &:hover {
            .sub-nav-list {
                opacity: 1;
                visibility: visible;
            }
        }
        a {
            font-weight: 300;
            position: relative;
            color: black;
            font-size: 16px;
            line-height: 1;

            &:hover {

                &:before {
                    width: 100%;
                    left: 0;
                    background: black;
                }
            }

            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 1px;
                left: 50%;
                bottom: -10px;
                transition: all .25s;
            }
        }
    }

    .sub-nav-list {
        position: absolute;
        width: 200px;
        left: -30px;
        text-align: left;
        margin-top: 21px;
        padding: 0 30px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s 0.3s ease;
        z-index: 99;
        /*background-color: white;*/

        .sub-nav-item {
            margin-bottom: 20px;
            .code-img {
                opacity: 0;
                /*visibility: hidden;*/
                /*transition: all 0.5s 0.3s ease;*/
                display: none;
            }
            &:hover {
                .code-img {
                    opacity: 1;
                    /*visibility: visible;*/
                    display: block;
                }
            }
            a {
                /*width: 100%;*/
                font-weight: 300;
                display: inline-block;
                position: relative;
                color: black;
                font-size: 14px;
                line-height: 1;
                transition: all .25s;

                &:hover {
                    transform: translateX(10px) translateZ(0);

                    &:before {
                        width: 100%;
                        right: 0;
                        background-color: black;
                    }

                }

                &:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    right: 100%;
                    height: 1px;
                    bottom: -10px;
                    transition: transform .25s;
                }
            }
        }
    }

    .nav-item-border {
         a {
            font-weight: bold;
            &:before {
                width: 100%;
                left: 0;
                background-color: black;
            }
        }
    }

    .app-nav-icon {
        position: absolute;
    }
</style>
