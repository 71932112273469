/**
 * Created by Elvira on 2020/5/13.
 */
export default {
    title:'CORPORATE SOCIAL RESPONSIBILITY',
    desc: 'As a responsible corporate citizen, ASC stays true to the core value of Suntory and gives back to the society. We are committed to always try to do the right thing, to take a long-term view, to support our clients and customers and the communities where we operate. Since the early years of ASC’s history, we have been actively involved in supporting underprivileged students to fulfil their educational dreams. In 2009 we founded the ASC Charity Fund which is dedicated to advance our corporate social responsibility cause. We will continue to support China’s social and economic development, focusing our community efforts on the areas of environmental sustainability and youth education in partnership with reputable NGOs in China like Shanghai Charity Foundation, Vibrant Communities, etc.',
    list: [{
        imgUrl: 'company/social/img_one.jpg',
        title: 'Shanghai Charity Foundation',
        desc: 'Shanghai Charity Foundation is a public non-profit organization approved by Shanghai non-government organization registration authorities. Since its founding in May 1994, SCF has always honored its commitment to raising donations, providing relief and spreading philanthropic awareness. It hosted multiple forms of charitable activities, engaging the public and social groups in charity work on a wide scale. So far, SCF has raised RMB 13.1 billion and expended RMB 10.1 billion, benefiting over 12.64 million people in difficulties.' +
        '<br><br>SCF has been awarded as Shanghai Model Institution repeatedly and is one of the first batch of AAAAA social organizations. It was also listed among the top 25 transparent foundations in China on Forbes.'
    }, /*{
        imgUrl: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/social/img_two.jpg'),
        title: '上海“初升的太阳”慈善基金会',
        desc: '“初升的太阳”是上海市慈善基金会旗下的慈善机构，自1996年成立以来，已为逾10,000名' +
        '来自上海困难家庭的学生提供奖学金资助。“初升的太阳”致力于为家庭条件困难的学生提供平等的' +
        '教育机会，协助其改善生活环境，实现职业理想，并在未来继续回馈社会。“初升的太阳”同时也为' +
        '上海资金匮乏的学区图书馆提供经济资助。'
    }, {
        imgUrl: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/social/img_three.png'),
        title: '根与芽',
        desc: '根与芽是国际性的环保及人道教育机构，用参与式的环境教育方式，鼓励根与芽组织成员积极' +
        '采取行动，保护环境、动物及我们所在的世界。根与芽组织于1991年由珍·古道尔女士与一群致力于' +
        '改变当地环境的坦桑尼亚学生成立，如今已发展为全球性的环保组织，并欢迎所有有志于改善环境的组员加入。'
    }, {
        imgUrl: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/social/img_four.jpg'),
        title: '美丽中国',
        desc: '美丽中国是一个教育非营利机构。为消除教育不平等现象，“美丽中国”从中国和美国寻找潜在' +
        '的未来领袖并加以培训和支持。“美丽中国”是“美丽世界”全球网络的一员，该网络致力于通过与未来' +
        '领导人才的合作，扩大成员所在国的教育机会。'
    }, {
        imgUrl: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/social/img_one.jpg'),
        title: 'Vibrant Communities',
        desc: '活力社区是一家公益机构，其核心目标是为处于社会边缘的流动人口建设社区活动中心。活力' +
        '社区致力于确保这些家庭能够在干净、安全的居住环境中学习与成长，并坚信他们有权获得体面的家园' +
        '环境，这属于社会大家庭的一部分。'
    }, */{
        imgUrl: 'company/social/img_two.jpg',
        title: 'Vibrant Communities',
        desc: 'Vibrant Communities, established in 2006, is a non-profit organization which is committed to creating a safe, purposeful and beneficial environment for disadvantaged children, especially migrant children and left-behind children. Its vision is to create “A VIBRANT FUTURE FOR EVERY CHILD”. It provides age-appropriate educational, health and safety courses along with other developmental activities. Also, Vibrant Communities works with local community groups and many different individual volunteers who assist in making the future “a vibrant future for every child” to provide access to early childhood education to migrant and left-behind children and their families.'
    },{
        imgUrl: 'company/social/img_five.jpg',
        title: 'Rendu Ocean',
        desc: 'Established in 2007, Rendu Ocean is currently the only NPO in China focusing on marine litter. Its vision is to restore cleanliness of our oceans and revitalize the harmonious relationship between humans and the ocean and its mission is to promote marine environment protection and reduce marine debris through coastal cleanup.'
    }]
}
