import axios from 'axios'
import store from '../store'

function interceptorsRequest(config) {
    let data = config.data || {};
    let params = {
        url: process.env.VUE_APP_CGI_URL,
        method: 'post',
        /*headers: {
         'X-Requested-With': 'XMLHttpRequest',
         'Content-Type': 'application/json',
         },*/
        header: {
            'Content-Type': 'application/json;CharSet=UTF-8',
        },
        responseType: 'json',
        data: {
            cmd: config.cmd || '',
            parameters: {
                ...data,
                language: store.state.base.lang
            },
        }
    };
    return params
}

export function request(inOpts) {
    return new Promise((resolve, reject) => {
        axios({
            ...interceptorsRequest(inOpts)
        }).then(res => {
            const {response, statusCode, error} = res.data;
            if (error) {
                reject(error || {})
            } else {
                switch (statusCode) {
                    case 0:
                        // if (response.data) {
                        if (response.data.error) {
                            if (response.data.errorCode == 419) {//服务器繁忙
                                resolve(response.data);
                            } else {
                                reject(response.data.error || {})
                            }
                        } else {
                            resolve(response.data);
                        }
                        break;
                    case 401: //（未授权） 请求要求身份验证。 对于需要登录的网页，服务器可能返回此响应。
                    case 403: //（禁止）服务器拒绝请求
                    case 410: //要获取的资源已经不存在或已删除
                    case 412: //（未满足前提条件）参数不合法
                    case 500: //服务器处理发生异常
                    default:
                        reject(error || {});
                }
            }
        }).catch(
            (error) => {
                reject(error)
            }
        );
    })
}