import {i18n} from '../../plugins/vuetify'
const localWelcome = sessionStorage.getItem('asc-locale-status') || 'false';
const localRemind = localStorage.getItem('asc-locale-remind') || 'false';
const actingMap = sessionStorage.getItem('asc-acting-map') || {};
const state = {
    lang: '',
    welcomeStatus: localWelcome,
    welcomeRemind: localRemind,
    headerDrawer: false,
    currentIndex: 0,
    mapType: actingMap.mapType || 'new',//世界类型
    countryId: actingMap.countryId || 0,//世界中的国家，默认全部0 ALL
};

// getters
const getters = {};

// actions
const actions = {
    switchLanguage({commit, dispatch}, lang) {
        commit('setLanguage', lang);
        window.location.reload();
    }
};

// mutations
const mutations = {
    setLanguage(state, key) {
        localStorage.setItem('asc-locale', key);
        state.lang = key;
        i18n.locale = key;
    },
    setDrawer: (state, payload) => (state.headerDrawer = payload),
    toggleDrawer: state => (state.headerDrawer = !state.headerDrawer),
    setWelcomeStatus(state, status){
        state.welcomeStatus = status;
        sessionStorage.setItem('asc-locale-status', status);
    },
    setWelcomeRemind(state, isRemind){
        state.welcomeRemind = isRemind;
        localStorage.setItem('asc-locale-remind', isRemind);
    },
    setMapType(state, type) {
        state.mapType = type;
        sessionStorage.setItem('asc-acting-map', {mapType: type, countryId: state.countryId});
    },
    setCountryId(state, id) {
        state.countryId = id;
        sessionStorage.setItem('asc-acting-map', {mapType: state.mapType, countryId: id});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
