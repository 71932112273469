export default {
    'app_title': 'ASC精品酒业',
    'Home': '首页',
    'Company': '公司',
    company: {
        'About Us': '关于我们',
        'History': '发展历程',
        'Enterprise': '企业文化',
        'Social Responsibility': '企业社会责任',
        'Press': '新闻资讯',
        'Contact Us': '联系我们',
        'ASC Tax ID': 'ASC纳税人识别号',
        'Drink Smart': '理性饮酒',
    },
    'Products': '我们的产品',
    products: {
        'Acting Red Wine': '葡萄酒',
        'GCC': '精品葡萄酒服务',
        'Water': '矿泉水',
        'Beer': '啤酒',
        'Liquor': '烈酒',
        'Group Products': '集团产品',
    },
    'Staff': '员工',
    staff: {
        'Responsibilities': '我们的职责',
        'Employees': '我们的员工',
        'Team': '管理团队',
        'Join Us': '加入我们',
    },
    'Services': '我们的服务',
    services: {
        'Events': '葡萄酒活动',
        'Education': '葡萄酒知识传授',
        'En Primeur': '期酒',
        'Auction': '精品葡萄酒拍卖',
    },
    'On Line': '线上购买',
    onLine: {
        'Jingdong': 'ASC京东旗舰店',
        'Tianmao': 'ASC天猫旗舰店',
        'Wechat': '微信小程序',
    },
    details: '详情',
    wineries: '酒庄',
    follow: '关注ASC',
    wechat: '微信',
    weibo: '微博',
    linkedin: '领英',
    moreBtn: '了解更多',
    contents: '内容',
    share: '分享文章至',
    all:'全部',
    new_world:'新世界',
    old_world:'旧世界',
    welcome:{
        title:'请填写您的生日',
        confirm:'确认',
        remember:'记住我',
        tip:'您必须达到所在国的法定饮酒年龄方可访问本网站<br>(中国内地及香港：18周岁）',
        tipOne:'请记住我',
        tipTwo:'请按照正确的格式输入您的出生日期，例如"08","08","1989"',
        tipThree:'您还未达到法定饮酒年龄！',
    },
    join:{
        position:'职位类别',
        positionDes:'职位描述',
        positionRem:'职位需求',
        city:'城市',
        job:'开启新的工作',
        send:'投递简历'
    }
}
