<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-navigation-drawer
            class="drawer"
            v-model="drawer"
            fixed
            temporary>
        <v-list nav dense>
            <div v-for="(nav,index) in navList" :key="index">
                <v-list-item v-if="nav.to" :to="nav.to">
                    <v-list-item-title>
                        {{$i18n.t(nav.key)}}
                    </v-list-item-title>
                </v-list-item>
                <v-list-group v-else>
                    <template v-slot:activator>
                        <v-list-item-title>{{$i18n.t(nav.key)}}</v-list-item-title>
                    </template>
                    <v-list-item v-for="(item,i) in nav.items" :to="item.to">
                        <a :href="item.href?item.href:'javascript:;'" target="_blank" v-if="item.href"><span :style="'color:'+textColor"> {{$i18n.t(item.key)}} </span></a>
                        <v-list-item-title v-else> {{$i18n.t(item.key)}} </v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import data from '@/config/nav'
    import {mapMutations} from 'vuex'
    export default {
        name: "app-drawer",
        data() {
            return {
                ...data
            }
        },
        props: {
            navIndex: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: 'rgba(255,255,255,1)'
            },
            textColor: {
                type: String,
                default: 'rgba(0,0,0,1)'
            },
            changeColor: {
                type: String,
                default: 'rgba(255,255,255,1)'
            }
        },
        computed: {
            drawer: {
                get() {
                    return this.$store.state.base.headerDrawer
                },
                set(val) {
                    this.setDrawer(val)
                }
            },
        },
        methods: {
            ...mapMutations('base', ['setDrawer']),
        }
    }
</script>

<style lang="scss" scoped>
.drawer{
    z-index: 99999 !important;
}
</style>
