const ossUrl =
  'https://website-asc.oss-cn-shanghai.aliyuncs.com/company/drink/';
export default {
  banner: {
    title: 'Drink Smart',
  },
  content1: {
    bg: ossUrl + 'bg1.jpg',
    title: 'Please Drink in Moderation',
    content:
      'Now drinking has become a staple in many adults’ social lives, whether it’s for making friends, celebrating, or even just enjoying a few glasses by oneself. However, the risks of excessive drinking are ever-present. As a fine wine company, ASC Fine Wines is convinced it’s our duty to highlight the impacts of overdrinking and to encourage you to “Drink in Moderation” so you can savor life’s brilliant moments with a fine glass of wine as ever.',
  },
  content2: {
    bg: ossUrl + 'bg2.jpg',
    title: 'Buy wine from Legal & Legitimate Sources',
    content:
      'The World Health Organization estimates that about a quarter of all alcohol beverage consumed globally is “illegal.” This type of alcohol beverage is often produced without following legal processes, which can compromise its quality and safety. So producers even use industrial ethanol instead of natural fermentation or add non-compliant ingredients to cut costs. These “illegal alcohols” might be sold on the black market or even repackaged as counterfeit products of well-known brands. <br><br> Media reports from around the world frequently highlight the severe health risks posed by these “illegal alcohols,” including poisoning, infections, and even death. To ensure a better and healthier experience when enjoying your wine, always purchase from legal and legitimate sources. For example, ASC Fine Wines guarantees the quality and provenance of every bottle of wine it imports and ASC’s Chinese back label is a symbol of warranty.',
    desc: '*According to “World Health Organization (WHO), Global Status Report on Alcohol and Health 2018. 2018, World Health Organization: Geneva.”',
  },
  content3: {
    title: 'Three Tips for Drinking in Moderation',
    content:
      'Wondering how to incorporate “Drinking in Moderation” into your life for a healthier and more enjoyable wine experience? Here are three practical tips:',
    list: [
      {
        icon: ossUrl + 'icon1.png',
        title: '1# Drink Water Alongside Wine',
        content:
          'Staying hydrated by drinking water while you drink wine helps prevent dehydration and naturally slows down your drinking pace, allowing you to enjoy your wine more leisurely.',
      },
      {
        icon: ossUrl + 'icon2.png',
        title: '2# Don’t Drink on an Empty Stomach',
        content:
          'Eating before drinking helps alcohol stay in your stomach longer, slowing its absorption in the small intestine and preventing a rapid spike in blood alcohol levels.',
      },
      {
        icon: ossUrl + 'icon3.png',
        title: '3# Plan Ahead Before Drinking',
        content:
          'Make a plan for getting home safely before you start drinking. It’s always better to be prepared.',
      },
    ],
  },
  content4: {
    bg: ossUrl + 'bg4.png',
    title: 'Don’t Drink and Drive',
    content:
      'According to the World Health Organization, alcohol-impaired driving is a leading cause of traffic accidents and fatalities worldwide. Alcohol impairs your reaction time, vision, and judgment, making it dangerous to drive. Laws also impose severe penalties for drunk driving. For your safety and the safety of others, never drive after drinking.',
  },
  content5: {
    bg: ossUrl + 'bg5.jpg',
    icon1: ossUrl + 'icon_5_1.png',
    icon2: ossUrl + 'icon_5_2.png',
    title: 'Pregnant Women and Minors Should Avoid Alcohol',
    content:
      'Research shows that pregnant women who drink regularly can significantly impact their developing fetus. Alcohol passes through the placenta, directly affecting the baby. For the health of your child, it’s wise to avoid alcohol during pregnancy. <br><br> The relevant Chinese laws also prohibit minors from drinking. Compared to adults, minors metabolize alcohol less efficiently and are more likely to experience adverse effects. Long-term drinking can increase the risk of cardiovascular disease, liver damage, and cancer in minors. Alcohol also affects brain development and can impair mental and self-control abilities, potentially causing harm to society and others. It’s important not to drink to “fit in” or “follow the crowd.” Just like fine wine, some things are worth waiting for.',
  },
  content6: {
    bg: ossUrl + 'bg6.jpg',
    icon: ossUrl + 'cup.png',
    title: 'Be Responsible for Yourself and Others',
    content:
      'Understanding the effects of alcohol and its impact on your body is crucial. Always take responsibility for yourself and others before thinking about enjoyment.',
    list: [
      'As a host, be mindful of everyone’s needs: don’t serve alcohol to minors, ensure guests’ safety, and provide plenty of water, food, and non-alcoholic beverages.',
      'Plan your drinking activities. Know your limits and make travel arrangements in advance.',
      'At gatherings, keep an eye on your drink, know your limits, and don’t drink excessively due to peer pressure.',
      'Respect the choices of those who choose not to drink or to drink less.',
      'If possible, share information about drinking in moderation with your family and friends, encouraging them to understand the dangers of excessive drinking and to prioritize their health.',
    ],
    last: 'Finally, may every gathering with wine, friends, and love be safe and enjoyable.',
  },
  content7: {
    title: 'Let’s Drink Smart together',
    content:
      'Suntory Group is a strong advocate for “Drink in Moderation.” For more information, visit Suntory’s “Drink Smart” website.',
  },
  btn: 'Suntory "Drink Smart" Website',
};
