/**
 * Created by Elvira on 2020/5/8.
 */
export default {
    list: [{
        title: 'OUR MISSION',
        items: ['To enrich consumers’ life by providing the world’s best wines and Suntory Group brands']
    }, {
        title: 'OUR VISION',
        items: ['To be a quality distribution platform which makes our brands shine', 'To be a lighthouse for wine consumers and trade']
    }, {
        title: 'OUR DNA',
        items: ['Sincerity', 'Provenance', 'Team spirit', 'Lifestyle']
    }, {
        title: 'OUR CREDO',
        items: ['We think<br>and move proactively', 'We express<br>value of products', 'We are a responsible<br>corporate citizen']
    }],
    mission: {
        title: 'OUR MISSION',
        enTitle: 'OUR MISSION',
        desc: 'To enrich consumers’ life by providing the world’s best wines and Suntory Group brands' +
        '<br><br>Our Mission tells why ASC exists. It constantly guides and inspires us. Our products –fine wines from around world as well as quality products from Suntory - provide people with enjoyment of fine things of life. We stay focused on the needs of our customers and consumers. It is our desire to promote abundant and ever-fulfilling lives. '
    },
    vision: {
        title: 'OUR VISION',
        enTitle: 'OUR VISION',
        desc: 'Our vision demonstrates what ASC aims to achieve as a leading fine wine importer and distributor in China. We are proud of the brands that we represent and we are confident that ASC is the platform where our brands can grow to impart their radiance. As a lighthouse, we don’t wait until deed of greatness we may do or wait to shed our light afar, we want to brighten every corner where our partners and consumers are.',
        items: [{
            desc: 'To be a quality distribution<br>platform which makes our<br>brands shine'
        }, {
            desc: 'To be a lighthouse for wine<br>consumers and trade'
        }]
    },
    dna: {
        title: 'OUR DNA',
        enTitle: 'OUR DNA',
        items: [{
            title: 'Sincerity',
            desc: 'it is the basic quality of ours to conduct business and to interact with people.',
            iconfont: 'iconxinyi'
        }, {
            title: 'Provenance',
            desc: 'it honours our commitment for quality and authenticity',
            iconfont: 'iconzhenyuan'
        }, {
            title: 'Team spirit',
            desc: 'it means mutual respect and teamwork, the wings with which we fly',
            iconfont: 'icontuanduijingshen'
        }, {
            title: 'Lifestyle',
            desc: 'it gives ASC the distinctive character as a company and its employees as individuals to embody the beautiful things of life.',
            iconfont: 'iconshenghuofangshi'
        }]
    },
    credo: {
        title: 'OUR CREDO',
        enTitle: 'OUR CREDO',
        items: [{
            title: 'We think<br>and move proactively',
        }, {
            title: 'We express<br>value of products',
        }, {
            title: 'We are a responsible<br>corporate citizen',
        }]
    },
}