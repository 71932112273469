export default {
    'app_title': 'ASC FINE WINES',
    'Home': 'Home',
    'Company': 'Our company',
    company: {
        'About Us': 'About us',
        'History': 'Milestones',
        'Enterprise': 'Our philosophy',
        'Social Responsibility': 'Social responsibility',
        'Press': 'Press',
        'Contact Us': 'Contact us',
        'ASC Tax ID': 'ASC Tax ID',
        'Drink Smart': 'Drink Smart',
    },
    'Products': 'Our portfolio',
    products: {
        'Acting Red Wine': 'Wines',
        'GCC': 'Fine wine service',
        'Water': 'Water',
        'Beer': 'Beer',
        'Liquor': 'Spirits',
        'Group Products': 'Group products',
    },
    'Staff': 'Our people',
    staff: {
        'Responsibilities': 'Functions',
        'Employees': 'Employees',
        'Team': 'Executive team',
        'Join Us': 'Join us',
    },
    'Services': 'Services',
    services: {
        'Events': 'Events',
        'Education': 'Education',
        'En Primeur': 'En Primeur',
        'Auction': 'Auction',
    },
    'On Line': 'Online shopping',
    onLine: {
        'Jingdong': 'JD.com Flagship Store',
        'Tianmao': 'Tmall flagship store',
        'Wechat': 'Wechat mini program',
    },
    details: 'details',
    wineries: 'wineries',
    follow: 'Follow us on social media',
    wechat: 'Wechat',
    weibo: 'Weibo',
    linkedin: 'LinkedIn',
    moreBtn: 'Learn more',
    contents: 'Contents',
    share: 'Share this article:',
    all: 'All',
    new_world: 'New World',
    old_world: 'Old World',
    welcome: {
        title: 'Please enter your date of birth',
        confirm: 'Confirm',
        remember: 'Remember me',
        tip: 'You must be over the legal drinking age<br>in your country to visit this website<br>（Mainland China and Hongkong: 18 years old or above )',
        tipOne: 'Please remember me',
        tipTwo: 'Please enter your date of birth in the correct format, e.g. "08", "08", "1989"',
        tipThree: "You're under legal drinking age!",
    },
    join: {
        position: 'Position category',
        positionDes: 'Job description',
        positionRem: 'Job requirements',
        city: 'City',
        job: 'Start a new job',
        send: 'Send resume'
    }
}
