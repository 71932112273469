<template>
    <div>
        <div class="welcome">
            <div class="bg hidden-sm-and-down"></div>
            <div class="content">
                <v-img class="logo" :src="require('assets/logo.png')" max-width="80"></v-img>
                <v-card class="mx-auto hidden-sm-and-down" min-width="766" min-height="418" shaped
                        color="rgba(255, 255, 255, 0.9)" flat>
                    <welcome></welcome>
                </v-card>
                <welcome class="hidden-md-and-up"></welcome>
            </div>

        </div>
        <app-footer style="position: absolute;bottom: 0px;width: 100%;z-index:1000"/>
    </div>

</template>

<script>
    import AppFooter from "components/core/footer/index";
    import Welcome from "../base/welcome";
    export default {components: {Welcome, AppFooter}}
</script>

<style lang="scss" scoped>
    .welcome {
        position: fixed;
        left: -15px;
        right: -15px;
        top: -15px;
        bottom: -15px;
        z-index: 999;
        overflow: hidden;
        background-color: transparent;
        .bg {
            width: 100%;
            height: 100%;
            background-image: url('https://website-asc.oss-cn-shanghai.aliyuncs.com/index/swiper_img_4.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            -webkit-filter: blur(8px);
            -moz-filter: blur(8px);
            -o-filter: blur(8px);
            -ms-filter: blur(8px);
            filter: blur(8px);
        }
    }

    @media (max-width: $screen-md) {
        .welcome {
            background-color: white;
        }
    }

    .content {
        position: fixed;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -60%);
        width: 766px;
        height: 488px;
        .logo {
            position: fixed;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 999;
            border: 5px solid white;
            border-radius: 50%;
        }
    }
</style>
