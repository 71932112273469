/**
 * Created by Elvira on 2020/5/13.
 */
export default {
    title:'企业社会责任',
    desc: '作为一个负责任的企业，ASC恪守三得利的核心价值观——回馈社会。我们致力于行事端正，目光长远，支持我们的客户、消费者以及我们所在的社会。在ASC创立之初的岁月里，我们就曾积极参与支持贫困学生的读书梦。2009年，我们成立了ASC慈善基金会，致力于推动我们企业的社会责任事业。 我们将继续支持中国的社会和经济发展，通过与上海市慈善基金会、活力社区等中国知名公益组织合作提供社区服务，我们致力于支持环境的可持续发展和下一代教育。',
    list: [{
        imgUrl: 'company/social/img_one.jpg',
        title: 'Shanghai Charity Foundation',
        desc: 'Shanghai Charity Foundation is a public non-profit organization approved by Shanghai non-government organization registration authorities. Since its founding in May 1994, SCF has always honored its commitment to raising donations, providing relief and spreading philanthropic awareness. It hosted multiple forms of charitable activities, engaging the public and social groups in charity work on a wide scale. So far, SCF has raised RMB 13.1 billion and expended RMB 10.1 billion, benefiting over 12.64 million people in difficulties.' +
        '<br><br>SCF has been awarded as Shanghai Model Institution repeatedly and is one of the first batch of AAAAA social organizations. It was also listed among the top 25 transparent foundations in China on Forbes.'
    },{
        imgUrl: 'company/social/img_two.jpg',
        title: 'Vibrant Communities',
        desc: 'Vibrant Communities, established in 2006, is a non-profit organization which is committed to creating a safe, purposeful and beneficial environment for disadvantaged children, especially migrant children and left-behind children. Its vision is to create “A VIBRANT FUTURE FOR EVERY CHILD”. It provides age-appropriate educational, health and safety courses along with other developmental activities. Also, Vibrant Communities works with local community groups and many different individual volunteers who assist in making the future “a vibrant future for every child” to provide access to early childhood education to migrant and left-behind children and their families.'
    },{
        imgUrl: 'company/social/img_three.jpg',
        title: 'Rendu Ocean',
        desc: 'Established in 2007, Rendu Ocean is currently the only NPO in China focusing on marine litter. Its vision is to restore cleanliness of our oceans and revitalize the harmonious relationship between humans and the ocean and its mission is to promote marine environment protection and reduce marine debris through coastal cleanup.'
    }]
}