/**
 * Created by Elvira on 2020/4/27.
 */
export default {
    about: {
        banner:{
            title: '关于我们',
            enTitle: 'ABOUT ASC',
        },
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/img_one.jpg'),
        title: '公司开始',
        enTitle: 'OUR BEGINNING',
        desc: '20世纪90年代中叶，圣皮尔先生（Don St. Pierre Sr.）和他的儿子相信中国进口葡萄酒行业发展在即。中国经济的开放、消费者收入的快速增长、国产葡萄酒产量不足，三者成为ASC创立的基础条件。自1996年公司建立以来，公司的发展远超预期。',
    },
    development: {
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/bg_two.jpg'),
        title: '公司发展',
        enTitle: 'ASC DEVELOPMENT',
        desc: '2010年，ASC精品酒业成为三得利集团成员之一。三得利早在100年前的创立之初，便有了“志在新，勇于行（Yatte Minahare – Go for it!）”的企业文化，鼓励成员敢于他人所不敢，通过不断创造新价值来贡献社会。正是同样的企业价值观，在此前的20年里一直支撑着ASC努力成为中国葡萄酒进口行业的领导者和值得信赖的优质葡萄酒供应商；此后岁月里，更将持续指导着我们。',
        items: [{
            title: '经验',
            desc: '我们有幸能从著名的葡萄酒生产者手中选择上佳的作品。迄今为止，ASC精品酒业独家进口、经销来自15个国家超过1000款不同的美酒，为中国的消费者提供了丰富的产品，优质的服务和选择空间。'
        }, {
            title: '客户',
            desc: 'ASC有幸能和来自全国不同地方，不同渠道的不同客户共同合作，无论酒店餐厅，零售商超，批发商或是作为葡萄酒爱好者的个人客户。'
        }]
    },
    service: {
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/img_two.jpg'),
        title: '葡萄酒服务和知识推广',
        enTitle: 'OUR EXPERTISE',
        desc: 'ASC团队的热情和专业知识来维护日常工作。对客户体验的不懈关注是我们成功的主要原因之一。坦言之，ASC不仅是葡萄酒的进口商和分销商，我们的团队能够提供专业的解决方案和服务定制，以满足客户对精品葡萄酒的多样化需求。',
    },
    product: {
        title: '公司产品',
        enTitle: 'COMPANY PRODUCTS',
        desc: 'ASC不仅关注葡萄酒销售业绩，更致力于开发市场。为此，我们向葡萄酒行业的从业者和消费者提供葡萄酒知识与文化服务。我们专业的葡萄酒教育人员会培训每一个员工，让所有面对客户的团队都能了解自己的产品，以匹配客户的业务需求。作为服务的一部分，ASC的葡萄酒教育团队还可以为客户提供量身定制的培训计划，使他们同样具备专业的葡萄酒知识，从而为他们的顾客提供卓越的消费者体验。超过客户的期望永远是ASC成功的关键。' +
        '<br><br>专业的物流是保障运输过程中葡萄酒品质的关键。ASC在北京，上海，广州，成都和香港设立了5个温控仓库，以就近接收来自全国的订单，以便ASC可以按时，足量，保质的交付货物。',
    },
    team: {
        img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/file/6931712730861_.pic.jpg',
        title: '管理团队',
        enTitle: 'EXECUTIVE TEAM',
        desc: '',
        items: [
            {
            imgUrl: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/teamnew-1.jpg',
            name: '长江淳',
            position: '首席执行总裁',
            desc: '长江淳先生于2021年6月15日被任命为ASC精品酒业首席执行总裁。<br><br>长江淳先生1990年本科毕业于日本神户外国语大学，中文专业，并于同年加入日本三得利集团。自2003年起，长江淳先生被委派到三得利集团在大中华地区各区域和不同的业务部门工作。在加入ASC之前，長江淳先生担任宾三得利中国台湾地区董事总经理。<br><br>凭借他对大中华市场的经验、才能和知识，加上他杰出的领导才能，他将为制定和实施公司的长期战略以及推动公司的可持续价值建设做出重要贡献。'
        },{
            imgUrl:'https://website-asc.oss-cn-shanghai.aliyuncs.com/file/6941712730866_.pic.jpg',
            name: '饭田敦彦',
            position: '首席财务官',
            desc:'饭田敦彦于2024年4月1日开始担任ASC精品酒业首席财务官的职务，负责财务部、业务计划分析、供应链管理、信息技术和法务等部门。作为ASC的执行委员会的重要成员，他推动跨部门合作，带头努力发现机会、解决障碍，优化资源利用。他的努力将对引导ASC实现业务目标、增强盈利能力以及促进公司可持续增长起到关键作用。'
        },{
            imgUrl:'https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/teamnew-4.jpg',
            name: '潘晓艳',
            position: '高级人力资源总监',
            desc:'Eileen于2017年7月加入ASC精品酒业，担任高级人力资源总监，全权负责人力资源部门的管理。<br><br>Eileen拥有资深的人力资源管理背景，在组织规划、薪资福利、员工保留、人才发展、战略规划等方面有着深厚的实战经验，她将是公司业务发展的重要支持。加入ASC之前，Eileen曾在众多快消品公司和奢侈品牌任职人力资源总监，譬如：雅诗兰黛中国、欧莱雅中国、Dazzle Fashion、斯沃琪集团中国公司。'
        }]
    }
}
