const ossUrl =
  'https://website-asc.oss-cn-shanghai.aliyuncs.com/company/drink/';
export default {
  banner: {
    title: '理性饮酒',
  },
  content1: {
    bg: ossUrl + 'bg1.jpg',
    title: '为自己，请适度饮酒',
    content:
      '如今，饮酒已成为不少成年人在交友、欢聚、独处等生活场景中不可或缺的一部分。然而，过量饮酒的风险无处不在。作为一家精品葡萄酒公司，ASC精品酒业有责任向您展示过度饮酒对身体带来的一系列影响，并倡议您“适度饮酒”，以更好地在美酒的陪伴下，享受人生的宝贵时光。',
  },
  content2: {
    bg: ossUrl + 'bg2.jpg',
    title: '请购买正规渠道销售的酒精饮品',
    content:
      '世界卫生组织估计，全世界消耗的所有酒中，大约有四分之一是“非法酒”* ，它们大多是未按照合法生产流程生产的酒，其产品质量和安全标准也存在或多或少的风险。有时，“非法酒”的制造商会使用现成的乙醇来代替自然发酵的流程，或在生产过程中添加各种不合规的原料，以降低生产成本，以次充好。这些“非法酒”可能直接在黑市上销售，更有甚者会经过重新包装，以知名品牌仿冒品的形式销售。<br><br>世界各个国家和地区的媒体常有相关报道，指出这些“非法酒”对消费者健康的严重危害，包括但不限于中毒 、感染，甚至死亡。因此，为了您在饮用美酒，欢享时光时收获更好、更健康的体验，在购买时，请务必选择正规渠道，例如，ASC精品酒业在其进口的葡萄酒酒瓶上张贴的中文背标，便代表了质量和产地的保证，可放心选购。',
    desc: '*该数据引自“World Health Organization (WHO), Global Status Report on Alcohol and Health 2018. 2018, World Health Organization: Geneva.”',
  },
  content3: {
    title: '关于适度饮酒的三则倡议',
    content:
      '如何将“适度饮酒”的标准落实到生活中，以更健康的方式享受愉悦生活？为解决这一问题，我们提出了三则切实可行的倡议，或许可以成为借鉴：',
    list: [
      {
        icon: ossUrl + 'icon1.png',
        title: '1# 边喝酒，边喝水',
        content:
          '在喝酒的同时摄入水份，这不仅能帮你保持水分平衡，避免脱水，还能自然而然地放慢饮酒的速度，让你更加从容地享受品酒的乐趣。',
      },
      {
        icon: ossUrl + 'icon2.png',
        title: '2# 不要空腹喝酒',
        content:
          '在喝酒前进食会让酒精能够在胃里停留更长时间，以减缓小肠对酒精的吸收，防止血液中的酒精浓度迅速上升。',
      },
      {
        icon: ossUrl + 'icon3.png',
        title: '3# 饮酒前，请先制定酒后计划',
        content: '在饮酒前提前计划好回家时间及方式，有备无患。',
      },
    ],
  },
  content4: {
    bg: ossUrl + 'bg4.png',
    title: '酒后不驾车',
    content:
      '根据世界卫生组织的统计数据表明，在全球范围内，酒精损伤性驾驶都是导致交通事故伤亡的主要原因之一。由于酒精对大脑中枢神经的麻痹作用，饮酒后，您的反应能力、视线清晰度，以及对距离、速度、突发情况的判断力都会显著减弱。同时，国家法律法规也对“饮酒驾车”与“醉酒驾车”制定了一系列严厉的处罚条款。因此，为了您和您的家人，也为了其他道路使用者的安全，饮酒后请不要驾车。',
  },
  content5: {
    bg: ossUrl + 'bg5.jpg',
    icon1: ossUrl + 'icon_5_1.png',
    icon2: ossUrl + 'icon_5_2.png',
    title: '孕妇及未成年人，请勿饮酒',
    content:
      '科学研究表明，经常性饮酒的孕妇，相比孕期从不饮酒的孕妇，对发育中的胎儿造成的影响颇为显著。酒精会随着血液循环进入胎盘，使婴幼儿在宫内直接受到酒精的影响，为了孩子的健康，孕期远离酒精是较为明智的决定。<br><br>根据相关法律规定，未成年人请勿饮酒。相比成年人，未成年人对酒精的代谢能力低，更容易因饮酒引起身体不适。若长期饮酒，未成年人罹患心血管、肝脏损伤，癌症的风险也会更高。此外，酒精还会影响大脑的正常发育，损害脑功能，心智与自控能力尚未健全的未成年人，也有概率因酒精的麻痹作用，对社会与他人造成危害。更重要的是，请不要为了“寻求同龄人/异性的认同”、“盲目跟风”或“模仿成年人”而饮酒，正如每一支酒都需要时间酝酿，再等一等，你才能真正发现它的美好。',
  },
  content6: {
    bg: ossUrl + 'bg6.jpg',
    icon: ossUrl + 'cup.png',
    title: '对自己负责，对他人负责',
    content:
      '在充分了解关于酒精的知识以及其对身体的影响之后，请始终在日常生活中首先为自己与他人负起责任，其次，再谈享受。',
    list: [
      '作为聚会的主人时，请始终关注到每个人的需求：不为未成年人提供酒精、确保客人的安全、提供足够的水食物以及无酒精饮料以供选择。',
      '有计划地进行饮酒活动。了解自己的“酒量”与身体情况，提前为出行做好安排。',
      '聚会中，请不要让你的酒饮离开你的视线，清楚自己的饮酒量，勿因“劝酒”而过量。',
      '若有人选择不饮酒或少饮酒，请尊重他们的选择。',
      '如果可以，请积极向你的家庭成员与伙伴好友分享“适量饮酒”的信息，鼓励身边人了解过量饮酒的危害，注重自己的身体与精神健康。',
    ],
    last: '最后，祝您每一次与酒、与友、与爱的相聚，都能安全且尽兴。',
  },
  content7: {
    title: '一起“Drink Smart”',
    content:
      '三得利集团同样是“适度饮酒”的长期倡导者，如欲获取更多信息，可点击至三得利“Drink Smart”官网了解详情。',
  },
  btn: '三得利“Drink Smart”官网',
};
