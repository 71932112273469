import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import vuetify from './plugins/vuetify';
import {i18n} from './plugins/vuetify';
import "./styles/app.scss";
import "./styles/quill.snow.css";
import 'swiper/css/swiper.css'
import api from './services/api'
// import 'swiper/js/swiper.min'
import './plugins/swiper/css/animate.min.css'
import utils from './utils/index'


//组件
import BaseBtn from 'components/base/Btn';
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper);
Vue.component('BaseBtn', BaseBtn);

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$utils = utils;

const lang = localStorage.getItem('asc-locale') || 'zhHans';
store.commit('base/setLanguage', lang);

new Vue({
    router,
    store,
    render: h => h(App),
    i18n,
    vuetify,
    mounted () {

    }
}).$mount('#app');
