/**
 * Created by Elvira on 2020/5/13.
 */
export default {
    auction: {
        title:'ASC拍卖行',
        enTitle:'ASC AUCTION HOUSE',
        tip: '除了期酒服务外，ASC精品酒拍卖也是我们精品酒业务的亮点：',
        desc: '为适应中国对精品葡萄酒日益增长的需求，ASC精品酒业组建了自己的精品葡萄酒拍卖队伍—圣皮尔（上海）拍卖有限公司 – 在2010年的11月，ASC成为持有拍卖执照的专业葡萄酒拍卖公司。<br><br>圣皮尔（上海）拍卖有限公司的建立，无疑为追寻各国珍酿的中国葡萄酒爱好者们提供一个新的途径。'
    },
    list: [{
        title: '正宗血统价格优惠',
        subTitle: '正宗血统价格优惠',
        desc: '凭借与各大知名酒庄的长期友好关系，ASC拍卖公司的拍品都直接由酒庄或酒庄授权的批发商供货。'
    }, {
        title: '完善的售后服务',
        subTitle: '',
        desc: 'ASC是致力于中国内地及港澳地区业务的专业葡萄酒公司，拥有专业完善的售后服务。'
    }, {
        title: '节省更多',
        subTitle: '',
        desc: 'ASC拍卖公司提供非常优惠的佣金制，更多让利于竞拍者。因此对于每位竞拍者来说，在ASC拍卖会上能够以具有相当竞争力的价格买到心爱的美酒佳酿。ASC精品酒业一年举办多场拍卖活动，为中国葡萄酒爱好者们提供更安心便捷的途径，来得到从波尔多五大酒庄到各大产区的葡萄酒生产名家的精选佳酿。'
    }],
    desc: '如需了解更多关于ASC拍卖有限公司，或者我们下一次拍卖会信息，欢迎联系我们的精品酒部门：auctioninquiry@asc-wines.com'
}
