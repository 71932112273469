<template>
    <div>
        <div class="m-center">
            <div class="display-3 text-color text-center">WELCOME</div>
            <div class="mt-3 mb-3 text-color title text-center">{{$i18n.t('common.welcome.title')}}</div>
            <div class="date-box">
                <div class="border-color" v-for="(item,index) in date" :class="index!=0?'ml':''">
                    <input :ref="'input'+index" class="border-input text-color subtitle-1"
                           :class="index==date.length-1?'input-w':''"
                           :placeholder="item.name"
                           :maxlength="index==date.length-1?4:2"
                           v-model="item.value"
                           @keyup.enter="index==date.length-1?enter():''"
                           @input="onInput($event,index)"/>
                </div>
                <div class="v-btn-box" @click="enter">
                    {{$i18n.t('common.welcome.confirm')}}
                    <!--<v-btn color="#fff" text height="50" width="150" tile @click="enter"></v-btn>-->
                </div>
            </div>
            <div class="subtitle-1 red--text text-center mb-3 mt-4" v-if="showError">{{showTitle}}</div>
            <div class="subtitle-1 text-color text-center mb-3 mt-4" v-html="$i18n.t('common.welcome.tip')"></div>
            <div class="remind text-center d-flex flex-row justify-center align-center">
                <input type="checkbox" v-model="remind" id="remind"/>
                <label class="text-color subtitle-1 ml-1" for="remind">{{$i18n.t('common.welcome.remember')}}</label>
            </div>
        </div>
    </div>

</template>
<script>
    import {mapMutations} from 'vuex'
    export default {
        name: "welcome",
        data() {
            return {
                date: [{
                    name: 'MM',
                    value: ''
                }, {
                    name: 'DD',
                    value: ''
                }, {
                    name: 'YYYY',
                    value: ''
                }],
                remind: true,
                showError: false,
                showTitle: ''
            }
        },
        methods: {
            ...mapMutations('base', ['setWelcomeStatus', 'setWelcomeRemind']),
            onInput(e, index){
                var dom = document.getElementsByClassName('border-input')
                var currInput = dom[index]
                var nextInput = dom[index + 1]
//                var lastInput = dom[index - 1]
                this.date[index].value = e.target.value.replace(/[^0-9]+/g, '')
                var num = this.date[index].value.length
                console.log(currInput.maxLength == num)
                if (currInput.maxLength == num) {
                    nextInput.focus();
                }
            },
            enter(){
                this.showTitle = ''
                this.showError = false
                if (this.checkDate()) {
                    this.setWelcomeStatus('enter');
                    if (!this.remind) {
                        this.setWelcomeRemind('false');
//                        this.showTitle = this.$i18n.t('common.welcome.tipOne')
//                        this.showError = true
                    } else {
                        this.setWelcomeRemind('true');
                    }
                    console.log('---', this.$store.state.base.welcomeStatus)
                    console.log('---', this.$store.state.base.welcomeRemind)
                }
            },
            checkDate(){
                this.showTitle = this.$i18n.t('common.welcome.tipTwo')
                this.date.forEach(item => {
                    if (!item.value) {
                        this.showError = true
                    }
                })
                if (this.showError) {
                    return false
                }
                var year = this.date[2].value
                if (year.length < 4) {
                    this.showError = true
                    return false
                }

                var month = this.date[0].value
                var day = this.date[1].value

                var isLeapYear = false
                var dayNum = 0
                if (year % 4) {
                    isLeapYear = true
                }
                if (month > 0 && month < 13) {
                    switch (parseInt(month)) {
                        case 2:
                            if (isLeapYear) {
                                dayNum = 29;
                            } else {
                                dayNum = 28;
                            }
                            break;
                        case 1:
                        case 3:
                        case 5:
                        case 7:
                        case 8:
                        case 10:
                        case 12:
                            dayNum = 31;
                            break;
                        case 4:
                        case 6:
                        case 9:
                        case 11:
                            dayNum = 30;
                            break;
                    }
                } else {
                    this.showError = true
                    return false
                }
                if (day <= 0 || day > dayNum) {
                    this.showError = true
                    return false
                }

                let nowDay = new Date().getTime()
                let check = nowDay - 18 * 365 * 24 * 60 * 60 * 1000
                let birthDays = new Date(year + '/' + month + '/' + day).getTime()
                if (birthDays < check) {
                    return true
                } else {
                    //未成年！
                    this.showTitle = this.$i18n.t('common.welcome.tipThree')
                    this.showError = true
                    return false
                }
            }

        }
    }
</script>
<style type="text/css" lang="scss" scoped>
    .m-center {
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -30%);
        .text-color {
            color: $primary-color
        }
        .date-box {
            display: flex;
            justify-content: center;
            .border-color {
                border: 2px solid $primary-color;
                input {
                    line-height: 50px;
                    outline-style: none;
                    width: 80px;
                    text-align: center;
                    color: $primary-color;
                }
                .input-w {
                    width: 140px;
                }
                input::-webkit-input-placeholder {
                    color: #D8D8D8;
                }

            }
            .v-btn-box {
                background: $primary-color;
                width: 150px;
                height: 54px;
                text-align: center;
                line-height: 54px;
                color: white;
                cursor: pointer;
            }
            .ml {
                margin-left: -2px;
            }
        }
        .remind {
            label {
                cursor: pointer;
                margin-top: 2px;
            }
            input[type='checkbox'] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                outline: none;
                width: 16px;
                height: 16px;
                cursor: pointer;
                text-align: center;
                content: '';
                background: transparent !important;
                z-index: 1;
                position: relative;
                border: 2px solid $primary-color;
            }
            input[type='checkbox']:after {
                position: absolute;
                top: -2px;
                left: -2px;
                text-align: center;
                width: 16px;
                height: 16px;
            }
            input[type='checkbox']:checked::after {
                content: '\2713';
                background-color: $primary-color;
                color: white;
            }
        }
    }

    @media (max-width: $screen-md) {
        .m-center {
            top: 20%;
            transform: translate(-50%, -20%);
            .display-3 {
                font-size: 30px !important;
            }
            .title {
                font-size: 20px !important;
            }
            .date-box {
                .border-color {
                    border: 1px solid $primary-color;
                    input {
                        width: 55px;
                        line-height: 40px;
                    }
                    .input-w {
                        width: 97px;
                    }
                }
                .v-btn-box {
                    width: 90px;
                    height: 42px;
                    line-height: 42px;
                    font-size: 14px;
                }
                .ml {
                    margin-left: -1px;
                }
            }
        }
    }
</style>